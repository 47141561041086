import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Form, FormGroup, FormLabel, FormControl, Button, Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import MediaService from './MediaService';

import config from '../../config';

const { awsS3BaseUrl } = config;

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  file: Yup.mixed()
});

function NewMedia() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState({
    name: '',
    file: ''
  });

  const [preview, setPreview] = useState();
  const [previewText, setPreviewText] = useState('Preview');

  useEffect(() => {
    async function GetData() {
      const response = await MediaService.getMediaById(id);
      let data = response.data;
      setInitialValues({ name: data.name, file: '' });
      setPreview(`${awsS3BaseUrl}${data.file_slug}`);
      setPreviewText('Uploaded Image');
    }
    let isSubscribed = true;

    if (isSubscribed && id) {
      GetData();
    }
    return () => (isSubscribed = false);
  }, [id]);

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      console.log(values);
      const newMedia = new FormData();
      newMedia.append('name', values.name);
      if (values.file && values.file != '') {
        newMedia.append('file', values.file);
      }

      if (id) {
        await MediaService.updateMedia(id, newMedia);
      } else {
        await MediaService.createMedia(newMedia);
      }
      goBack();
    } catch (error) {
      console.log(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">{id ? 'Edit' : 'New'} Media</h1>
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={FormSchema}
        validateOnMount={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          isValid
        }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup as={Col} controlId="formName">
              <FormLabel>Name</FormLabel>
              <FormControl
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.name && errors.name}
              />
              <FormControl.Feedback type="invalid">{errors.name}</FormControl.Feedback>
            </FormGroup>
            <FormGroup as={Col} controlId="formImage">
              <FormLabel>Image</FormLabel>
              <FormControl
                type="file"
                name="file"
                accept=".jpg,.jpeg,.png,.gif"
                onChange={(event) => {
                  console.log(event.currentTarget.files);
                  if (event.currentTarget.files.length > 0) {
                    setFieldValue('file', event.currentTarget.files[0]);
                    setPreview(URL.createObjectURL(event.currentTarget.files[0])); // Update the preview image
                    setPreviewText('Preview');
                  }
                }}
                onBlur={handleBlur}
                isInvalid={touched.file && errors.file}
              />
              <FormControl.Feedback type="invalid">{errors.file}</FormControl.Feedback>
            </FormGroup>
            <Row className="mt-3">
              <Col sm={6}>
                <Button type="submit" disabled={!isValid || isSubmitting || preview == undefined}>
                  Submit
                </Button>
                <Link to={`/medias`} className="btn btn-danger m-1">
                  Cancel
                </Link>
              </Col>
              <Col sm={6}>
                {previewText}:
                {preview && <img className="border w-100" src={preview} alt="Preview" />}
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default NewMedia;
