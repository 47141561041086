import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormControl, FormLabel, FormGroup, Button, Col, Row, InputGroup } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import CharacterService from './CharacterService';
import MediaService from '../media/MediaService';

import config from '../../config';

const { awsS3BaseUrl } = config;

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  image: Yup.string(),
  facts: Yup.array().of(Yup.string().required('Fact is required'))
});

const NewCharacterForm = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const [preview, setPreview] = useState();

  // Start Media
  const [imageOptions, setImageOptions] = useState([]);

  const loadMedia = async () => {
    const response = await MediaService.getMedias();
    console.log(response.data);
    let data = response.data
      .filter((media) => !media.deletedAt)
      .map((item) => {
        return { value: item, label: item.name };
      });
    setImageOptions(data);
  };
  // End Media

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState({
    name: '',
    image: '',
    facts: ['']
  });

  useEffect(() => {
    async function GetData() {
      const response = await CharacterService.getCharacterById(id);
      let data = response.data;
      console.log(data);
      setInitialValues({
        name: data.name,
        image: data.image._id,
        // facts: []
        facts: [...data.facts]
      });
      if (data.image && data.image != '') {
        setPreview(`${awsS3BaseUrl}${data.image.file_slug}`);
      }
    }
    let isSubscribed = true;
    loadMedia();
    if (isSubscribed && id) {
      GetData();
    }

    return () => (isSubscribed = false);
  }, [id]);

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">{id ? 'Edit' : 'New'} Character</h1>
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={FormSchema}
        validateOnMount={true}
        onSubmit={async (values) => {
          console.log('Submitting form with values: ', values);
          try {
            if (id) {
              await CharacterService.updateCharacter(id, values);
            } else {
              await CharacterService.createCharacter(values);
            }
          } catch (error) {
            console.log(error);
          }
          goBack();
        }}
      >
        {({
          values,
          setFieldValue,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <FormGroup as={Col} controlId="formName">
                  <FormLabel>Name</FormLabel>
                  <FormControl
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
                <FormGroup as={Col} controlId="formImage">
                  <FormLabel>Image</FormLabel>
                  <Field type="text" name="image">
                    {({ field, form }) => {
                      return (
                        <Select
                          name={field.name}
                          options={imageOptions}
                          value={imageOptions.find((option) => option.value._id === field.value)}
                          onChange={(imageOptions) => {
                            form.setFieldValue(field.name, imageOptions.value._id);
                            setPreview(`${awsS3BaseUrl}${imageOptions.value.file_slug}`);
                            console.log(imageOptions.value);
                          }}
                          onBlur={field.onBlur}
                          className={
                            form.touched.image && form.errors.image
                              ? 'react-select is-invalid'
                              : 'react-select'
                          }
                        />
                      );
                    }}
                  </Field>
                </FormGroup>
                Preview:
                {preview && preview != '' && (
                  <img className="border w-100" src={preview} alt="Preview" />
                )}
              </Col>
              <Col>
                <FormGroup as={Col} controlId="formFacts">
                  <FormLabel>Facts</FormLabel>
                  {values.facts.map((fact, index) => (
                    <InputGroup key={`ig-${index}`} as={Col} style={{ padding: '10px' }}>
                      <InputGroup.Text key={`lb-${index}`}>{index + 1}</InputGroup.Text>
                      <FormControl
                        key={`fc-${index}`}
                        className="p-1"
                        type="text"
                        value={fact}
                        name={`facts.${index}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Button
                        variant="outline-secondary"
                        onClick={() =>
                          setFieldValue(
                            'facts',
                            values.facts.filter((_, i) => i !== index)
                          )
                        }
                      >
                        Remove
                      </Button>
                    </InputGroup>
                  ))}
                  <Button
                    type="button"
                    className="m-1"
                    onClick={() => setFieldValue('facts', [...values.facts, ''])}
                  >
                    Add Fact
                  </Button>
                </FormGroup>
              </Col>
            </Row>
            <Button type="submit" className="m-1 mt-5" disabled={!isValid || isSubmitting}>
              Submit
            </Button>
            <Link to={`/characters`} className="btn btn-danger m-1  mt-5">
              Cancel
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewCharacterForm;
