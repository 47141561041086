import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';

function Confirmation(props) {
  let { modalOpts, show } = props;
  return (
    <Modal show={show} onHide={modalOpts.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{modalOpts.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{modalOpts.body}</p>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={modalOpts.btnClick}>
          {modalOpts.btnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

Confirmation.propTypes = {
  modalOpts: PropTypes.shape({
    btnClick: PropTypes.func,
    onClose: PropTypes.func,
    body: PropTypes.string,
    title: PropTypes.string,
    btnText: PropTypes.string
  }),
  show: PropTypes.bool
};

export default Confirmation;
