import axiosInstance from '../../services/axios.service';

const base_url = '/sites';

class SiteService {
  getSites() {
    return axiosInstance.get(base_url);
  }
  createSite(site) {
    if (site.sponsorLogo == '') {
      site.sponsorLogo = null;
    }
    return axiosInstance.post(base_url, site);
  }
  getSiteById(siteId) {
    return axiosInstance.get(`${base_url}/${siteId}`);
  }
  updateSite(siteId, site) {
    if (site.sponsorLogo == '') {
      site.sponsorLogo = null;
    }
    return axiosInstance.put(`${base_url}/${siteId}`, site);
  }

  updateSiteSpace(siteId, space) {
    return axiosInstance.put(`${base_url}/${siteId}/space`, space);
  }

  deleteSite(siteId) {
    return axiosInstance.put(`${base_url}/delete/${siteId}`);
  }

  setSiteActive(siteId) {
    return axiosInstance.put(`${base_url}/setactive/${siteId}`);
  }
  setSiteInactive(siteId) {
    return axiosInstance.put(`${base_url}/setinactive/${siteId}`);
  }
}

export default new SiteService();
