import * as AWS from 'aws-sdk';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CognitoRefreshToken
} from 'amazon-cognito-identity-js';
import { BehaviorSubject } from 'rxjs';
import { alertService } from './alert.service';

const userPoolId = process.env.REACT_APP_AWS_USER_POOL_ID;
const clientId = process.env.REACT_APP_AWS_CLIENT_ID;

AWS.config.update({
  region: process.env.REACT_APP_AWS_REGION
});

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`
};
console.log(process.env);
console.log(poolData);
const userPool = new CognitoUserPool(poolData);

const $sessionUserAttributes = new BehaviorSubject({});
const $accessToken = new BehaviorSubject(null);
const $idToken = new BehaviorSubject(null);
const $cognitoUser = new BehaviorSubject({});

export const dataService = {
  setSessionUserAttributes: (d) => $sessionUserAttributes.next(d),
  clearSessionUserAttributes: () => $sessionUserAttributes.next(),
  getSessionUserAttributes: () => $sessionUserAttributes.asObservable(),

  setAccessToken: (d) => $accessToken.next(d),
  clearAccessToken: () => $accessToken.next(),
  getAccessToken: () => $accessToken.asObservable(),

  setIdToken: (d) => $idToken.next(d),
  clearIdToken: () => $idToken.next(),
  getIdToken: () => $idToken.asObservable(),

  setCognitoUser: (d) => $cognitoUser.next(d),
  clearCognitoUser: () => $cognitoUser.next(),
  getCognitoUser: () => $cognitoUser.asObservable()
};

function successLogin(res) {
  dataService.setAccessToken(res.getAccessToken().getJwtToken());
  dataService.setIdToken(res.getIdToken().getJwtToken());
  localStorage.setItem('refreshToken', res.getRefreshToken().token);

  const loginStr =
    'cognito-idp.' + process.env.REACT_APP_AWS_REGION + '.amazonaws.com/' + userPoolId;

  const creds = {
    IdentityPoolId: process.env.REACT_APP_AWS_IDENTIFY_POOL,
    Logins: {}
  };
  console.log(loginStr);
  console.log(creds);
  creds.Logins[loginStr] = $idToken.getValue();
  AWS.config.credentials = new AWS.CognitoIdentityCredentials(creds);

  // refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
  AWS.config.credentials.refresh((error) => {
    if (error) {
      console.error(error);
    } else {
      // Instantiate aws sdk service objects now that the credentials have been updated.
      // example: var s3 = new AWS.S3();
      console.log('Successfully logged!');
    }
  });
}

export async function getAttributes() {
  return new Promise(function (resolve, reject) {
    $cognitoUser.getValue().getUserAttributes(function (err, attributes) {
      if (err) {
        reject(err);
      } else {
        resolve(attributes);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signInWithEmail(username, password) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: userPool
    };

    dataService.setCognitoUser(new CognitoUser(userData));

    $cognitoUser.getValue().authenticateUser(authenticationDetails, {
      onSuccess: function (res) {
        successLogin(res);
        localStorage.setItem('username', username);
        resolve(res);
      },
      onFailure: function (err) {
        console.log(err);
        reject(err);
      },
      // mfaRequired: function(codeDeliveryDetails) {
      //     // MFA is required to complete user authentication.
      //     // Get the code from user and call
      //     // cognitoUser.sendMFACode(mfaCode, this)
      // },

      newPasswordRequired: function (userAttributes, requiredAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.
        console.log(requiredAttributes);
        // the api doesn't accept this field back
        delete userAttributes.email_verified;
        delete userAttributes.email;
        // store userAttributes on global variable
        dataService.setSessionUserAttributes(userAttributes);
        resolve('new_pw_req');
      }
    });
  }).catch((err) => {
    console.log(err);
    throw err;
  });
}

export async function signInWithRefresh(refreshToken) {
  return new Promise(function (resolve, reject) {
    const authenticationDetails = new CognitoRefreshToken({ RefreshToken: refreshToken });
    // cognitoUser.refreshSession(token, (err, session) => { ... });
    // const authenticationDetails = new AuthenticationDetails(authenticationData)

    // // getCognitoUser(username)
    const userData = {
      Username: localStorage.getItem('username'),
      Pool: userPool
    };

    dataService.setCognitoUser(new CognitoUser(userData));

    // $cognitoUser.getValue().refreshSession(authenticationDetails, {
    //   onSuccess: function (res) {
    //     console.log('call result: ' + res);
    //     successLogin(res)
    //     resolve(res)
    //   },
    //   onFailure: function (err) {
    //     console.log(err);
    //     reject(err)
    //   }
    // })
    $cognitoUser.getValue().refreshSession(authenticationDetails, (err, res) => {
      if (err) {
        reject(err);
      }
      successLogin(res);
      resolve(res);
    });
  }).catch((err) => {
    console.log(err);
    throw err;
  });
}

export function resetPasswordWithCode(code, newPassword) {
  return new Promise(function (resolve, reject) {
    $cognitoUser.getValue().confirmPassword(code, newPassword, {
      onSuccess: function (res) {
        // successLogin(res)
        alertService.success('Success! New Please login again with your new Password.', {
          autoClose: false,
          keepAfterRouteChange: true
        });
        resolve(res);
      },
      onFailure: function (err) {
        console.log('call error: ' + err);
        reject(err);
      }
    });
  });
}

export function handleNewPassword(newPassword) {
  return new Promise(function (resolve, reject) {
    $cognitoUser
      .getValue()
      .completeNewPasswordChallenge(newPassword, $sessionUserAttributes.getValue(), {
        onSuccess: function (res) {
          successLogin(res);
          resolve(res);
        },
        onFailure: function (err) {
          console.log('call error: ' + err);
          reject(err);
        }
      });
  });
}

export function signOut(fnc) {
  if ($cognitoUser.getValue()) {
    localStorage.clear('username');
    localStorage.clear('refreshToken');

    $cognitoUser.getValue().signOut(fnc);
    console.log($cognitoUser.getValue());
    dataService.setAccessToken(null);
    dataService.setIdToken(null);
  }
}
