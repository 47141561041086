import axiosInstance from '../../services/axios.service';

const base_url = '/characters';

class CharacterService {
  getCharacters() {
    return axiosInstance.get(base_url);
  }
  createCharacter(character) {
    return axiosInstance.post(base_url, character);
  }
  getCharacterById(id) {
    return axiosInstance.get(`${base_url}/${id}`);
  }
  updateCharacter(id, character) {
    return axiosInstance.put(`${base_url}/${id}`, character);
  }

  deleteCharacter(id) {
    return axiosInstance.put(`${base_url}/delete/${id}`);
  }
}

export default new CharacterService();
