import React, { useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Row, Col, FormGroup, FormLabel, Modal } from 'react-bootstrap';
import Select from 'react-select';
import CharacterService from '../character/CharacterService';
import MediaService from '../media/MediaService';
import { useNavigate, useParams } from 'react-router-dom';
import SiteService from './SiteService';
import config from '../../config';
import { format } from 'date-fns';

import Confirmation from '../confirmation/Confirmation';

const { awsS3BaseUrl } = config;

const SiteDetail = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const [isEdit, setIsEdit] = useState(false);
  const [spaceInd, setSpaceInd] = useState(-1);
  const [showForm, setShowForm] = useState(false);

  const [showActiveConfirm, setShowActiveConfirm] = useState(false);
  const [showInactiveConfirm, setShowInactiveConfirm] = useState(false);

  const [activateConfirmationOptions, setActivateConfirmationOptions] = useState({
    title: 'Confirm Activation',
    body: 'Are you sure you want to set this as your ACTIVE site? All other sites will be deactivated.',
    btnText: 'Yes',
    btnClick: () => {
      setShowActiveConfirm(false);
    },
    onClose: () => {
      setShowActiveConfirm(false);
    }
  });

  const [deactivateConfirmationOptions, setDeactivateConfirmationOptions] = useState({
    title: 'Confirm Deavtivation',
    body: 'Are you sure you want to set site to INACTIVE? You will not have any active sites until you activate one.',
    btnText: 'Yes',
    btnClick: () => {
      setShowInactiveConfirm(false);
    },
    onClose: () => {
      setShowInactiveConfirm(false);
    }
  });

  // Start Characters
  const [characterOptions, setCharactersOptions] = useState([]);

  const loadCharacters = async () => {
    const response = await CharacterService.getCharacters();
    let data = response.data
      .filter((char) => !char.deletedAt)
      .map((item) => {
        return { value: item, label: item.name };
      });
    setCharactersOptions(data);
  };
  // End Characters

  const [siteData, setSiteData] = useState({
    name: '',
    logo: '',
    sponsorLogo: '',
    tncLink: '',
    privacyLink: '',
    startDate: '',
    endDate: '',
    spaces: []
  });

  const { id } = useParams();
  const [preview, setPreview] = useState();

  // Start Media
  const [imageOptions, setImageOptions] = useState([]);

  const loadMedia = async () => {
    const response = await MediaService.getMedias();
    let data = response.data
      .filter((media) => !media.deletedAt)
      .map((item) => {
        return { value: item, label: item.name };
      });
    setImageOptions(data);
  };
  // End Media

  async function GetData() {
    const response = await SiteService.getSiteById(id);
    let data = response.data;
    console.log(data);
    setSiteData(data);
  }

  useEffect(() => {
    GetData(id);
    loadCharacters();
    loadMedia();
  }, []);

  const [initialValues, setInitialValues] = useState({
    characters: [],
    locationHint: '',
    image: ''
  });

  const gotoEditSpace = (values, index) => {
    setIsEdit(true);
    setSpaceInd(index);
    setShowForm(true);
    console.log(values, index);
    setInitialValues({
      characters: values.characters.map((char) => char._id),
      locationHint: values.locationHint,
      image: values.image._id
    });
    setPreview(`${awsS3BaseUrl}${values.image.file_slug}`);
    // load into form for editing
    //set initial values for form here
  };

  const saveSpace = async (values) => {
    const newSpaces = [...siteData.spaces];
    newSpaces.push({
      characters: values.characters,
      locationHint: values.locationHint,
      image: values.image
    });
    // setSiteData({
    //   ...siteData,
    //   spaces: newSpaces
    // });
    // console.log(siteData);
    // console.log(newSpaces);

    // let newData = { ...siteData };
    // newData.spaces = newSpaces;

    const resp = await SiteService.updateSiteSpace(id, newSpaces);
    console.log(resp);
    GetData(id);
    // setSiteData(resp.data);
  };

  const updateSpace = async (values, index) => {
    const newSpaces = [...siteData.spaces];
    newSpaces[index] = {
      characters: values.characters,
      locationHint: values.locationHint,
      image: values.image
    };
    const resp = await SiteService.updateSiteSpace(id, newSpaces);
    console.log(resp);
    GetData(id);
    // setSiteData(resp.data);
  };

  const deleteSpace = async (spaceIndex) => {
    const newSpaces = [...siteData.spaces];
    newSpaces.splice(spaceIndex, 1);
    const resp = await SiteService.updateSiteSpace(id, newSpaces);
    console.log(resp);
    GetData(id);
    // setSiteData(resp.data);
  };

  const setActive = () => {
    //save the site logic then go back
    // goBack();
    setShowActiveConfirm(true);

    setActivateConfirmationOptions({
      ...activateConfirmationOptions,
      btnClick: async () => {
        await SiteService.setSiteActive(id);
        setShowActiveConfirm(false);
        GetData(id);
      }
    });
  };

  const setInactive = () => {
    //save the site logic then go back
    // goBack();
    setShowInactiveConfirm(true);

    setDeactivateConfirmationOptions({
      ...deactivateConfirmationOptions,
      btnClick: async () => {
        await SiteService.setSiteInactive(id);
        setShowInactiveConfirm(false);
        GetData(id);
      }
    });
  };

  return (
    <div>
      {siteData.name && (
        <Row>
          <Col>
            <h2>{siteData.name}</h2>
            {siteData.active ? (
              <div>
                <h4 className="text-success">ACTIVE</h4>
                <Button className="float-end" onClick={() => setInactive()}>
                  Deactivate
                </Button>
              </div>
            ) : (
              <Button className="float-end" onClick={() => setActive()}>
                Activate
              </Button>
            )}
          </Col>
          <Col>
            <div>
              <label>Logo:</label>
              <br />
              {siteData.logo && siteData.logo.file_slug ? (
                <img
                  src={awsS3BaseUrl + siteData.logo.file_slug}
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    width: '150px'
                  }}
                />
              ) : (
                <span>No Image</span>
              )}
            </div>
          </Col>
          <Col>
            <div>
              <label>Sponsor Logo:</label>
              <br />
              {siteData.sponsorLogo && siteData.sponsorLogo.file_slug ? (
                <img
                  src={awsS3BaseUrl + siteData.sponsorLogo.file_slug}
                  style={{
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    width: '100px'
                  }}
                />
              ) : (
                <span>No Image</span>
              )}
            </div>
          </Col>
          <Col>
            <div>
              <label>T&C Link: </label>
              <a href={siteData.tncLink}>{siteData.tncLink}</a>
            </div>
            <div>
              <label>Privacy Link: </label>
              <a href={siteData.privacyLink}>{siteData.privacyLink}</a>
            </div>
            <div>
              <label>Start Date: </label>
              <label>{format(new Date(siteData.startDate), 'yyyy-MM-dd')}</label>
            </div>
            <div>
              <label>End Date: </label>
              <label>{format(new Date(siteData.endDate), 'yyyy-MM-dd')}</label>
            </div>
            <div>
              <label>Redemption Location:</label>
              <label>{siteData.redeemLocation}</label>
            </div>
          </Col>
          <Col className="d-flex flex-column">
            {/* <Button className="btn m-1" onClick={() => saveSite()}>
              Save Changes
            </Button> */}
            <div>
              <Button className="btn m-1 float-end" onClick={() => goBack()}>
                Back to Sites
              </Button>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <div>
          <Button
            className="btn m-1 float-end"
            onClick={() => {
              setInitialValues({
                characters: [],
                locationHint: '',
                image: ''
              });
              setPreview(null);
              setShowForm(true);
              setIsEdit(false);
              setSpaceInd(-1);
            }}
          >
            Add Space
          </Button>
        </div>
      </Row>

      <table className="table mb-5">
        <thead>
          <tr>
            <th>#</th>
            <th>Characters</th>
            <th>Location Hint</th>
            <th>Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {siteData.spaces.length == 0 && (
            <tr>
              <td colSpan="4">
                <span>no spaces created</span>
              </td>
            </tr>
          )}
          {siteData.spaces.map((space, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <ul>
                  {space.characters.map((char) => (
                    <li key={char._id}>
                      {char.image ? (
                        <img
                          src={awsS3BaseUrl + char.image.file_slug}
                          style={{
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            width: '75px'
                          }}
                        ></img>
                      ) : (
                        <span>No Image</span>
                      )}
                      <span>{char.name}</span>
                    </li>
                  ))}
                </ul>
              </td>
              <td>{space.locationHint}</td>
              <td>
                {' '}
                {space.image ? (
                  <img
                    src={awsS3BaseUrl + space.image.file_slug}
                    style={{
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      width: '150px'
                    }}
                  ></img>
                ) : (
                  <span>No Image</span>
                )}
              </td>
              <td>
                {' '}
                <Button className="btn m-1" onClick={() => gotoEditSpace(space, index)}>
                  Edit
                </Button>{' '}
                <Button className="btn btn-danger m-1" onClick={() => deleteSpace(index)}>
                  Remove
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal show={showForm} onHide={() => setShowForm(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add a new Space</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object({
              characters: Yup.array().required('Required'),
              locationHint: Yup.string().required('Required'),
              image: Yup.string().required('Required')
            })}
            validateOnMount={true}
            onSubmit={(value) => {
              console.log(value);
              console.log(isEdit);
              if (isEdit && spaceInd >= 0) {
                setShowForm(false);
                updateSpace(value, spaceInd);
              } else {
                setShowForm(false);
                saveSpace(value);
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <FormGroup as={Col} controlId="formCharacters">
                  <FormLabel>Characters</FormLabel>
                  <Field type="text" name="characters">
                    {({ field, form }) => {
                      return (
                        <Select
                          name={field.name}
                          options={characterOptions}
                          value={characterOptions.filter((option) =>
                            field.value.includes(option.value._id)
                          )}
                          onChange={(selectedOptions) => {
                            form.setFieldValue(
                              field.name,
                              selectedOptions.map((option) => option.value._id)
                            );
                            console.log(selectedOptions.map((option) => option.value));
                          }}
                          isMulti
                          onBlur={field.onBlur}
                          className={
                            form.touched.image && form.errors.image
                              ? 'react-select is-invalid'
                              : 'react-select'
                          }
                        />
                      );
                    }}
                  </Field>
                </FormGroup>
                <FormGroup as={Col} controlId="formLocationHint">
                  <FormLabel>Location Hint</FormLabel>
                  <Field name="locationHint">
                    {({ field, form }) => (
                      <input
                        type="text"
                        {...field}
                        placeholder="Location Hint"
                        className={
                          form.touched.locationHint && form.errors.locationHint
                            ? 'form-control is-invalid'
                            : 'form-control'
                        }
                      />
                    )}
                  </Field>
                </FormGroup>
                <FormGroup as={Col} controlId="formImage">
                  <FormLabel>Image</FormLabel>
                  <Field type="text" name="image">
                    {({ field, form }) => {
                      return (
                        <Select
                          name={field.name}
                          options={imageOptions}
                          value={imageOptions.find((option) => option.value._id === field.value)}
                          onChange={(imageOptions) => {
                            form.setFieldValue(field.name, imageOptions.value._id);
                            setPreview(`${awsS3BaseUrl}${imageOptions.value.file_slug}`);
                            console.log(imageOptions.value);
                          }}
                          onBlur={field.onBlur}
                          className={
                            form.touched.image && form.errors.image
                              ? 'react-select is-invalid'
                              : 'react-select'
                          }
                        />
                      );
                    }}
                  </Field>
                </FormGroup>
                <Row>
                  <Col>
                    Preview:
                    {preview && preview != '' && (
                      <img className="border w-100" src={preview} alt="Preview" />
                    )}
                  </Col>
                  <Col className="mt-3">
                    <Button className=" float-end" type="submit" disabled={isSubmitting}>
                      Save
                    </Button>
                    {/* <Button className="btn btn-danger m-1" onClick={() => setShowForm(false)}>
                      Cancel
                    </Button> */}
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Confirmation modalOpts={activateConfirmationOptions} show={showActiveConfirm}></Confirmation>
      <Confirmation
        modalOpts={deactivateConfirmationOptions}
        show={showInactiveConfirm}
      ></Confirmation>
    </div>
  );
};

export default SiteDetail;
