import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { FormControl, FormLabel, FormGroup, Button, Col, Row } from 'react-bootstrap';
import { addDays, format } from 'date-fns';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SiteService from './SiteService';
import MediaService from '../media/MediaService';
import Select from 'react-select';

import config from '../../config';

const { awsS3BaseUrl } = config;

const FormSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  logo: Yup.string().required('Logo is required'),
  redeemLocation: Yup.string().required('Logo is required'),
  sponsorLogo: Yup.string(),
  tncLink: Yup.string().required('T&C Link is required'),
  privacyLink: Yup.string().required('Privacy Link is required'),
  startDate: Yup.date().required('Start date is required'),
  endDate: Yup.date().required('End date is required')
});

const NewSiteForm = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  const { id } = useParams();

  const [initialValues, setInitialValues] = useState({
    name: '',
    logo: '',
    redeemLocation: '',
    sponsorLogo: '',
    tncLink: '',
    privacyLink: '',
    startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    endDate: format(addDays(new Date(), 2), 'yyyy-MM-dd')
  });

  const [logoPreview, setLogoPreview] = useState();
  const [sponsorPreview, setSponsorPreview] = useState();

  // Start Media
  const [imageOptions, setImageOptions] = useState([]);

  const loadMedia = async () => {
    const response = await MediaService.getMedias();
    console.log(response.data);
    let data = response.data
      .filter((media) => !media.deletedAt)
      .map((item) => {
        return { value: item, label: item.name };
      });
    setImageOptions(data);
  };
  // End Media

  useEffect(() => {
    async function GetData() {
      const response = await SiteService.getSiteById(id);
      let data = response.data;
      console.log(data);
      setInitialValues({
        name: data.name,
        logo: data.logo?._id || '',
        redeemLocation: data.redeemLocation,
        sponsorLogo: data.sponsorLogo?._id || '',
        tncLink: data.tncLink,
        privacyLink: data.privacyLink,
        startDate: format(new Date(data.startDate), 'yyyy-MM-dd'),
        endDate: format(new Date(data.endDate), 'yyyy-MM-dd')
      });

      if (data.logo && data.logo != '') {
        setLogoPreview(`${awsS3BaseUrl}${data.logo.file_slug}`);
      }
      if (data.sponsorLogo && data.sponsorLogo != '') {
        setSponsorPreview(`${awsS3BaseUrl}${data.sponsorLogo.file_slug}`);
      }
    }
    let isSubscribed = true;
    loadMedia();
    if (isSubscribed && id) {
      GetData();
    }
    return () => (isSubscribed = false);
  }, [id]);

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">{id ? 'Edit' : 'New'} Site</h1>
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={FormSchema}
        validateOnMount={true}
        onSubmit={async (values) => {
          console.log('Submitting form with values: ', values);
          try {
            if (id) {
              await SiteService.updateSite(id, values);
              goBack();
            } else {
              await SiteService.createSite(values);
              goBack();
            }
          } catch (error) {
            console.log(error);
          }
        }}
      >
        {({ handleChange, handleBlur, handleSubmit, isSubmitting, isValid, values }) => (
          <Form onSubmit={handleSubmit}>
            <FormGroup as={Col} controlId="formName">
              <FormLabel>Name</FormLabel>
              <FormControl
                type="text"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup as={Col} controlId="formRedeemLocation">
              <FormLabel>Redemption Location</FormLabel>
              <FormControl
                type="text"
                name="redeemLocation"
                value={values.redeemLocation}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <Row>
              <Col>
                <FormGroup as={Col} controlId="formLogo">
                  <FormLabel>Logo</FormLabel>
                  <Field type="text" name="logo">
                    {({ field, form }) => {
                      return (
                        <Select
                          name={field.name}
                          options={imageOptions}
                          value={imageOptions.find((option) => option.value._id === field.value)}
                          onChange={(imageOptions) => {
                            form.setFieldValue(field.name, imageOptions.value._id);
                            setLogoPreview(`${awsS3BaseUrl}${imageOptions.value.file_slug}`);
                            console.log(imageOptions.value);
                          }}
                          onBlur={field.onBlur}
                          className={
                            form.touched.image && form.errors.image
                              ? 'react-select is-invalid'
                              : 'react-select'
                          }
                        />
                      );
                    }}
                  </Field>
                </FormGroup>
              </Col>
              <Col>
                Preview:
                {logoPreview && logoPreview != '' && (
                  <img
                    style={{
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      width: '75px',
                      margin: '4px'
                    }}
                    src={logoPreview}
                    alt="Preview"
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup as={Col} controlId="formSponsorLogo">
                  <FormLabel>Sponsor Logo</FormLabel>
                  <Field type="text" name="sponsorLogo">
                    {({ field, form }) => {
                      return (
                        <Select
                          name={field.name}
                          options={imageOptions}
                          value={imageOptions.find((option) => option.value._id === field.value)}
                          onChange={(imageOptions) => {
                            form.setFieldValue(field.name, imageOptions.value._id);
                            setSponsorPreview(`${awsS3BaseUrl}${imageOptions.value.file_slug}`);
                            console.log(imageOptions.value);
                          }}
                          onBlur={field.onBlur}
                          className={
                            form.touched.image && form.errors.image
                              ? 'react-select is-invalid'
                              : 'react-select'
                          }
                        />
                      );
                    }}
                  </Field>
                </FormGroup>
              </Col>
              <Col>
                Preview:
                {sponsorPreview && sponsorPreview != '' && (
                  <img
                    style={{
                      border: '1px solid #ddd',
                      borderRadius: '4px',
                      width: '75px',
                      margin: '4px'
                    }}
                    src={sponsorPreview}
                    alt="Preview"
                  />
                )}
              </Col>
            </Row>
            <FormGroup as={Col} controlId="formTncLink">
              <FormLabel>T&C Link</FormLabel>
              <FormControl
                type="text"
                name="tncLink"
                value={values.tncLink}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup as={Col} controlId="formPrivacyLink">
              <FormLabel>Privacy Link</FormLabel>
              <FormControl
                type="text"
                name="privacyLink"
                value={values.privacyLink}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <Row>
              <FormGroup as={Col} controlId="formStartDate">
                <FormLabel>Start Date</FormLabel>
                <FormControl
                  type="date"
                  name="startDate"
                  value={values.startDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>
              <FormGroup as={Col} controlId="formEndDate">
                <FormLabel>End Date</FormLabel>
                <FormControl
                  type="date"
                  name="endDate"
                  value={values.endDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>
            </Row>
            <Button type="submit" className="m-1 mt-5" disabled={!isValid || isSubmitting}>
              Submit
            </Button>
            <Link to={`/sites`} className="btn btn-danger m-1 mt-5">
              Cancel
            </Link>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewSiteForm;
