import React, { useState, useEffect, useRef } from 'react';
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader
} from 'react-bs-datatable';
import { Col, Row, Table, Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

import UsersService from './UsersService';
import format from 'date-fns/format';

// Create table headers consisting of 4 columns.
const headers = [
  { title: 'Name', prop: 'name', isFilterable: true, isSortable: true },
  { title: 'Email', prop: 'email', isFilterable: true, isSortable: true },
  { title: 'Phone', prop: 'phone' },
  { title: 'Prizes', prop: 'awarded', isFilterable: true, isSortable: true },
  { title: 'Created', prop: 'createdAt', isFilterable: true, isSortable: true },
  { title: 'Centre', prop: 'siteName', isFilterable: true, isSortable: true }
];

function Users() {
  const [users, setUsers] = useState([]);
  const [fileName, setFileName] = useState('');

  const csvLink = useRef(); // setup the ref that we'll use for the hidden CsvLink click once we've updated the data

  const loadUsers = async () => {
    const results = await UsersService.getUsers();
    const filteredResults = results.data
      .filter((user) => !user.deletedAt)
      .map((user) => {
        user.siteName = user.site.name;
        user.createdAt = format(new Date(user.createdAt), 'dd/MMM/yy HH:mm');
        delete user.site;
        return user;
      });
    setUsers(filteredResults);
  };

  useEffect(() => {
    loadUsers();
    console.log(users);
  }, []);

  const downloadClicked = () => {
    setFileName(`QIC NOT ROCKET SCIENCE Users - ${format(new Date(), 'dd-MMM-yy HH:mm')}.csv`);
    csvLink.current.link.click();
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Users</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Button onClick={downloadClicked}>Download Users to csv</Button>
          <CSVLink
            data={users}
            filename={`${fileName}`}
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        </div>
      </div>
      <DatatableWrapper
        body={users}
        headers={headers}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [10, 20, 30, 40, 50]
          }
        }}
      >
        <Row className="mb-4">
          <Col xs={12} lg={4} className="d-flex flex-col justify-content-end align-items-end">
            <Filter />
          </Col>
        </Row>
        <Table>
          <TableHeader />
          <TableBody />
        </Table>
        <Row className="mb-4">
          <Col
            xs={12}
            sm={6}
            lg={6}
            className="d-flex flex-col justify-content-lg-start align-items-center justify-content-sm-start mb-2 mb-sm-0"
          >
            <PaginationOptions />
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={6}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Pagination />
          </Col>
        </Row>
      </DatatableWrapper>
    </div>
  );
}

export default Users;
