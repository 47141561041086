import React, { useState, useEffect } from 'react';
import CharacterService from './CharacterService';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import config from '../../config';

const { awsS3BaseUrl } = config;

import Confirmation from '../confirmation/Confirmation';

function Character() {
  const [search, setSearch] = useState('');

  // Start Characters
  const [characters, setCharacters] = useState([]);

  const [showConfirm, setShowConfirm] = useState(false);

  const [confirmationOptions, setConfirmationOptions] = useState({
    title: 'Confirm Delete',
    body: 'Are you sure you want to delete this character?',
    btnText: 'Yes',
    btnClick: () => {
      setShowConfirm(false);
    },
    onClose: () => {
      setShowConfirm(false);
    }
  });

  const loadCharacters = async () => {
    const results = await CharacterService.getCharacters();
    console.log(results.data);
    const filteredResults = results.data.filter((character) => !character.deletedAt);
    setCharacters(filteredResults);
  };
  // End Characters

  useEffect(() => {
    loadCharacters();
  }, []);

  const deleteHandler = async (id) => {
    await CharacterService.deleteCharacter(id);
    setCharacters(characters.filter((item) => item.id !== id));
    loadCharacters();
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Characters</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Link to="/add-character" className="btn btn-primary m-1">
            New Character
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <input
            type="text"
            className="form-control"
            placeholder="Search by heading"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Image</th>
                <th>Facts</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {characters
                .filter((item) => item?.name?.toLowerCase().includes(search.toLowerCase()))
                .map((character) => (
                  <tr key={character._id}>
                    <td>{character.name}</td>
                    <td>
                      {character.image ? (
                        <img
                          src={awsS3BaseUrl + character.image.file_slug}
                          style={{
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            width: '150px'
                          }}
                        ></img>
                      ) : (
                        <span>No Image</span>
                      )}
                    </td>
                    <td>
                      <ul>
                        {character.facts.map((fact) => (
                          <li key={fact}>{fact}</li>
                        ))}
                      </ul>
                    </td>
                    <td>
                      <Link to={`/add-character/${character._id}`} className="btn btn-primary m-1">
                        Edit
                      </Link>
                      <Button
                        type="button"
                        className="m-1"
                        variant="danger"
                        onClick={() => {
                          setConfirmationOptions({
                            ...confirmationOptions,
                            btnClick: () => {
                              deleteHandler(character?._id);
                              setShowConfirm(false);
                            }
                          });
                          setShowConfirm(true);
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Confirmation modalOpts={confirmationOptions} show={showConfirm}></Confirmation>
    </div>
  );
}

export default Character;
