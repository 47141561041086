import axiosInstance from '../../services/axios.service';

const base_url = '/medias';

class MediaService {
  getMedias() {
    return axiosInstance.get(base_url);
  }
  async createMedia(newMedia) {
    return await axiosInstance.post(`${base_url}/image`, newMedia, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  getMediaById(mediaId) {
    return axiosInstance.get(`${base_url}/${mediaId}`);
  }

  async updateMedia(mediaId, media) {
    return await axiosInstance.put(`${base_url}/image/${mediaId}`, media, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  deleteMedia(mediaId) {
    return axiosInstance.put(`${base_url}/delete/${mediaId}`);
  }
}

export default new MediaService();
