import React, { useState, useEffect } from 'react';
import SiteService from './SiteService';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { format } from 'date-fns';

import Confirmation from '../confirmation/Confirmation';

import config from '../../config';

const { awsS3BaseUrl } = config;

function Site() {
  const navigate = useNavigate();

  const [sites, setSites] = useState([]);
  const [search, setSearch] = useState('');

  const [showConfirm, setShowConfirm] = useState(false);

  const [confirmationOptions, setConfirmationOptions] = useState({
    title: 'Confirm Delete',
    body: 'Are you sure you want to delete this site?',
    btnText: 'Yes',
    btnClick: () => {
      setShowConfirm(false);
    },
    onClose: () => {
      setShowConfirm(false);
    }
  });

  const GetData = async () => {
    const results = await SiteService.getSites();
    const filteredResults = results.data.filter((item) => !item.deletedAt);
    setSites(filteredResults);
  };

  useEffect(() => {
    let isSubscribed = true;

    if (isSubscribed) {
      GetData();
    }
    return () => (isSubscribed = false);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const deleteHandler = async (id) => {
    await SiteService.deleteSite(id);
    setSites(sites.filter((item) => item.id !== id));
    GetData();
  };

  const gotoSiteDetails = (id) => {
    console.log('asdad');
    navigate(`/site/${id}`);
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Sites</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Link to={`/add-site`} className="btn btn-primary m-2">
            Add New Site
          </Link>
        </div>
      </div>
      <input
        type="text"
        className="form-control"
        placeholder="Search by name"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Row>
        {sites
          .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
          .map((site) => (
            <Col key={site._id} xs={12} sm={6} md={4}>
              <Card
                className="m-3 d-flex justify-content-center align-items-center hover-gray"
                style={{ cursor: 'pointer' }}
              >
                <Card.Header
                  className="w-100 d-flex flex-column justify-content-center align-items-center "
                  onClick={() => {
                    gotoSiteDetails(site._id);
                  }}
                >
                  {site.logo && site.logo.file_slug ? (
                    <Card.Img variant="top" src={awsS3BaseUrl + site.logo.file_slug} />
                  ) : (
                    <span>No Logo</span>
                  )}
                </Card.Header>
                <Card.Body
                  className="w-100 d-flex flex-column justify-content-center align-items-center "
                  onClick={() => {
                    gotoSiteDetails(site._id);
                  }}
                >
                  {site.active && <Card.Title className="text-success">ACTIVE</Card.Title>}
                  <Card.Title>{site.name}</Card.Title>
                  <Card.Text>
                    <b>Start Date:</b> {format(new Date(site.startDate), 'yyyy-MM-dd')} <br />
                    <b>End Date:</b> {format(new Date(site.endDate), 'yyyy-MM-dd')} <br />
                    <b>Activated Spaces:</b> {site.spaces.length}
                  </Card.Text>
                </Card.Body>
                <Card.Footer className="w-100 d-flex justify-content-end">
                  <Link to={`/add-site/${site._id}`} className="btn btn-primary m-2">
                    Edit
                  </Link>
                  <Button
                    className=" m-2"
                    variant="danger"
                    onClick={() => {
                      setConfirmationOptions({
                        ...confirmationOptions,
                        btnClick: () => {
                          deleteHandler(site._id);
                          setShowConfirm(false);
                        }
                      });
                      setShowConfirm(true);
                    }}
                  >
                    Delete
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          ))}
      </Row>
      <Confirmation modalOpts={confirmationOptions} show={showConfirm}></Confirmation>
    </div>
  );
}

export default Site;
