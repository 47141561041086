import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Link, Navigate } from 'react-router-dom';

import Alerter from './components/alert/Alert';

// import config from './config';
import LoginPage from './components/auth/Login';
import { signOut, dataService, signInWithRefresh } from './services/aws.service';
import { Button } from 'react-bootstrap';

import Site from './components/site/Site';
import NewSite from './components/site/NewSite';
import SiteDetail from './components/site/SiteDetail';

import Character from './components/character/Character';
import NewCharacter from './components/character/NewCharacter';
import NewMedia from './components/media/NewMedia';
import Media from './components/media/Media';
import Users from './components/users/Users';

function Root() {
  const [accessToken, setAccessToken] = useState();
  const [idToken, setIdToken] = useState();

  const logOut = async () => {
    signOut(() => {
      console.log('Logged Out!');
    });
  };

  dataService.getAccessToken().subscribe((res) => {
    if (accessToken !== res) {
      setAccessToken(res);
    }
  });

  dataService.getIdToken().subscribe((res) => {
    if (idToken !== res) {
      setIdToken(res);
    }
  });

  return (
    <div className="container">
      <Alerter />
      {!accessToken ? (
        <div>
          <header>
            <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
              <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
                <span className="fs-4">QIC ROCKET SCIENCE CMS</span>
              </a>
            </div>
          </header>
          <main>
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </main>
        </div>
      ) : (
        <div>
          <header>
            <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4 border-bottom">
              <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
                <span className="fs-4">QIC ROCKET SCIENCE CMS</span>
              </a>
              <nav className="d-inline-flex mt-2 mt-md-0 ms-md-auto">
                <Link className="me-3 py-2 text-dark text-decoration primary" to="/sites">
                  Sites
                </Link>
                <Link className="me-3 py-2 text-dark text-decoration" to="/characters">
                  Characters
                </Link>
                <Link className="me-3 py-2 text-dark text-decoration" to="/medias">
                  Media
                </Link>
                <Link className="me-3 py-2 text-dark text-decoration" to="/users">
                  Users
                </Link>
              </nav>
              <Button className="m-1" onClick={logOut}>
                Log Out
              </Button>
            </div>
          </header>

          <main>
            <Routes>
              <Route path="/sites" element={<Site />} />
              <Route path="/site/:id" element={<SiteDetail />} />
              <Route path="/add-site" element={<NewSite />} />
              <Route path="/add-site/:id" element={<NewSite />} />

              <Route path="/characters" element={<Character />} />
              <Route path="/add-character" element={<NewCharacter />} />
              <Route path="/add-character/:id" element={<NewCharacter />} />

              <Route path="/medias" element={<Media />} />
              <Route path="/add-media" element={<NewMedia />} />
              <Route path="/add-media/:id" element={<NewMedia />} />

              <Route path="/users" element={<Users />} />

              <Route path="*" element={<Navigate to="/sites" />} />
            </Routes>
          </main>
        </div>
      )}
    </div>
  );
}

function App() {
  const refreshSession = async () => {
    if (localStorage.getItem('username') && localStorage.getItem('refreshToken')) {
      try {
        await signInWithRefresh(localStorage.getItem('refreshToken'));
      } catch (error) {
        console.log(error);
        localStorage.clear('username');
        localStorage.clear('refreshToken');
      }
    }
  };
  refreshSession();

  return (
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  );
}
export default App;
