import React, { useState, useEffect } from 'react';
import MediaService from './MediaService';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import config from '../../config';
import Confirmation from '../confirmation/Confirmation';

const { awsS3BaseUrl } = config;

function Media() {
  const [medias, setMedias] = useState([]);
  // const [search, setSearch] = useState('');

  const [showConfirm, setShowConfirm] = useState(false);

  const [confirmationOptions, setConfirmationOptions] = useState({
    title: 'Confirm Delete',
    body: 'Are you sure you want to delete this media?',
    btnText: 'Yes',
    btnClick: () => {
      setShowConfirm(false);
    },
    onClose: () => {
      setShowConfirm(false);
    }
  });

  const loadMedia = async () => {
    const results = await MediaService.getMedias();
    const filteredResults = results.data.filter((media) => !media.deletedAt);
    setMedias(filteredResults);
  };

  useEffect(() => {
    loadMedia();
  }, []);

  const deleteHandler = async (id) => {
    await MediaService.deleteMedia(id);
    setMedias(medias.filter((media) => media.id !== id));
    let isSubscribed = true;

    if (isSubscribed) {
      loadMedia();
    }
    return () => (isSubscribed = false);
  };

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">Media</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <Link to="/add-media" className="btn btn-primary m-1">
            New Media
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {/* <input
            type="text"
            className="form-control"
            placeholder="Search by name"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          /> */}
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Media</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {medias
                // .filter((media) => media.name?.toLowerCase().includes(search.toLowerCase()))
                .map((media) => (
                  <tr key={media?._id ?? ''}>
                    <td>{media?.name ?? ''}</td>
                    <td>
                      {media.state === 'uploaded' && (
                        <img
                          alt={media?.name}
                          src={`${awsS3BaseUrl}${media.file_slug}`}
                          style={{
                            border: '1px solid #ddd',
                            borderRadius: '4px',
                            width: '150px'
                          }}
                        />
                      )}
                    </td>
                    <td>
                      <Link to={`/add-media/${media?._id ?? ''}`} className="btn btn-primary m-1">
                        Edit
                      </Link>
                      <Button
                        type="button"
                        className="m-1"
                        variant="danger"
                        onClick={() => {
                          setConfirmationOptions({
                            ...confirmationOptions,
                            btnClick: () => {
                              deleteHandler(media?._id);
                              setShowConfirm(false);
                            }
                          });
                          setShowConfirm(true);
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <Confirmation modalOpts={confirmationOptions} show={showConfirm}></Confirmation>
    </div>
  );
}

export default Media;
