import axiosInstance from '../../services/axios.service';

const base_url = '/users';

class UsersService {
  getUsers() {
    return axiosInstance.get(base_url);
  }
}

export default new UsersService();
